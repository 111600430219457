<script setup lang="ts">
import { ref, computed } from 'vue'
import {
  Layout as TLayout,
  Header as THeader,
  Aside as TAside,
  Content as TContent,
  Footer as TFooter,
  MenuItem as TMenuItem,
  HeadMenu as THeadMenu,
  Button as TButton,
  Icon as TIcon,
} from 'tdesign-vue-next';
import { MyMenu } from '@myfront/tdesign-components';

import { useAppConfig, navigateTo, useRoute } from '#imports'

const route = useRoute()

const { mainMenu } = useAppConfig()

const collapsed = ref(false);

const iconName = computed(() => (collapsed.value ? 'chevron-right' : 'chevron-left'));

const changeCollapsed = () => {
  collapsed.value = !collapsed.value;
};

const clicked = (menu: unknown) => {
  if (menu.value) {
    navigateTo(menu.value)
  }
}

const checkPath = (reg: string) => {
  const regex = new RegExp(reg, 'g');
  return regex.test(route.fullPath)
}

const currentMenu = computed(() => {
  const currentPath = route.fullPath
  // 查找菜单
  let target
  const findMenu = (menus: unknown) => {
    menus.map((menu) => {
      if (menu.path === currentPath || (menu.meta.match && menu.meta.match.some(checkPath))) {
        target = menu.path
      }

      if (!target && menu.children) {
        findMenu(menu.children)
      }
    })
  }
  
  findMenu(mainMenu.navigation)

  return target
})
const contentWidth = computed(() => (collapsed.value ? 'calc(100% - 64px)' : 'calc(100% - 232px)'))

const logo1 = '/logo.png'
const logo_small = '/logo.svg'

</script>
<template>
  <t-layout>
    <t-header style="position:sticky;top:0;z-index: 9999;">
      <t-head-menu
        theme="dark"
        class="my-head-menu"
      >
        <template #logo>
          <img
            v-if="!collapsed"
            width="136"
            class="logo"
            :src="logo1"
            alt="logo"
          >
          <img
            v-if="collapsed"
            width="32"
            class="logo"
            :src="logo_small"
            alt="logo"
          >
          <t-button
            class="t-demo-collapse-btn"
            variant="text"
            shape="square"
            @click="changeCollapsed"
          >
            <template #icon>
              <t-icon
                name="view-list"
                color="white"
              />
            </template>
          </t-button>
        </template>
        <!-- <t-menu-item value="item1">
          已选内容
        </t-menu-item>-->
        <template #operations>
          <a href="javascript:;"><t-icon
            class="t-menu__operations-icon"
            name="search"
          /></a>
          <a href="javascript:;"><t-icon
            class="t-menu__operations-icon"
            name="notification-filled"
          /></a>
          <a href="javascript:;"><t-icon
            class="t-menu__operations-icon"
            name="home"
          /></a>
        </template>
      </t-head-menu>
    </t-header>
    <t-layout>
      <t-aside style="position:fixed;border-top: 1px solid var(--component-border)">
        <MyMenu
          :value="currentMenu"
          :expanded="mainMenu.expanded"
          theme="dark"
          :style="{ height: 'calc(100vh - 56px)' }"
          :collapsed="collapsed"
          :menu="mainMenu.navigation"
          @clicked="clicked"
        >
          <template #operations>
            <t-button
              class="t-demo-collapse-btn"
              variant="text"
              shape="square"
              @click="changeCollapsed"
            >
              <template #icon>
                <t-icon
                  color="white"
                  :name="iconName"
                />
              </template>
            </t-button>
          </template>
        </MyMenu>
      </t-aside>
      <t-layout>
        <t-content>
          <div :style="{ height: '100vh', overflow: 'auto', width: '100%', paddingLeft: `calc(100% - ${contentWidth})` }">
            <slot />
          </div>
        </t-content>
      </t-layout>
    </t-layout>
  </t-layout>
</template>
<style>
.t-menu {
  background: var(--td-gray-color-13) !important;
  background-color: rgba(38, 47, 62, 1) !important;
}

.t-default-menu__inner>.t-menu {
  background-color: #151822 !important;
}

.t-default-menu__inner>.t-menu__operations {
  background-color: #151822 !important;
}

.t-head-menu__inner {
  border-bottom: 1px solid var(--td-gray-color-10);
}

.t-icon {
  fill: none !important
}

.my-head-menu>.t-head-menu {
  background-color: rgba(38, 47, 62, 1) !important;
}

.my-head-menu>.t-head-menu>.t-menu {
  background-color: rgba(38, 47, 62, 1) !important;
}

.t-menu__content {
  color: white !important
}
</style>